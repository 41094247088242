import { Injectable } from '@rxdi/core';
import * as firebase from 'firebase/app';
import { from, Observable, Subject, of } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import {
  SetProviderType,
  FacebookProvider,
  GithubProvider,
  TwitterProvider,
  GoogleProvider,
  ProvidersType,
} from './social.injections';

@Injectable({ init: true })
export class SocialService {
  isLoggedIn: Subject<boolean> = new Subject();
  constructor() {
    firebase.auth().onAuthStateChanged(user => {
      this.isLoggedIn.next(!!user);
    });
  }

  getUser(): Observable<firebase.User> {
    return of(firebase.auth().currentUser.toJSON() as firebase.User);
  }

  getUserRef() {
    return of(firebase.auth().currentUser);
  }

  signInWithGithub(fn: SetProviderType<GithubProvider> = p => p) {
    return from(firebase.auth().signInWithPopup(fn(new firebase.auth.GithubAuthProvider())));
  }

  signInWithFacebook(fn: SetProviderType<FacebookProvider> = p => p) {
    return from(firebase.auth().signInWithPopup(fn(new firebase.auth.FacebookAuthProvider())));
  }

  signInWithGoogle(fn: SetProviderType<GoogleProvider> = p => p) {
    return from(firebase.auth().signInWithPopup(fn(new firebase.auth.GoogleAuthProvider())));
  }

  signInWithTwitter(fn: SetProviderType<TwitterProvider> = p => p) {
    return from(firebase.auth().signInWithPopup(fn(new firebase.auth.TwitterAuthProvider())));
  }

  signInWithEmailAndPassword(email: string, password: string) {
    return from(firebase.auth().signInWithEmailAndPassword(email, password));
  }

  createUserWithEmailAndPassword(email: string, password: string) {
    return from(firebase.auth().createUserWithEmailAndPassword(email, password));
  }

  signOut() {
    return from(firebase.auth().signOut()).pipe(tap(() => this.isLoggedIn.next(false)));
  }

  checkLoggedIn() {
    return new Observable<boolean>(o => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        o.next(!!user);
        o.complete();
        unsubscribe();
      });
      return () => unsubscribe();
    });
  }

  resetPassword(email: string) {
    return from(firebase.auth().sendPasswordResetEmail(email));
  }

  linkAccount(type: ProvidersType) {
    return this.getUserRef().pipe(switchMap(userRef => userRef.linkWithPopup(this.getProvider(type))));
  }

  getProvider(type: ProvidersType) {
    if (type === 'facebook') {
      return new firebase.auth.FacebookAuthProvider();
    }

    if (type === 'github') {
      return new firebase.auth.GithubAuthProvider();
    }

    if (type === 'google') {
      return new firebase.auth.GoogleAuthProvider();
    }

    if (type === 'twitter') {
      return new firebase.auth.TwitterAuthProvider();
    }
  }

  updateProfile(displayName: string, photoURL: string) {
    return this.getUserRef().pipe(
      switchMap(res =>
        res.updateProfile({
          displayName,
          photoURL,
        })
      )
    );
  }
}
