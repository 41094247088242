

import { Plugin, PluginInterface, ModuleService } from '@rxdi/core';

@Plugin({ init: true })
export class FadeinPlugin implements PluginInterface {
  name = 'MyPlugin';
  version = '1.0.0';

  constructor(
    private moduleService: ModuleService,
  ) {}

  async register() {
    this.getMetaDescriptors();
  }

  async handler(request, h) {
    return 'Hello world';
  }
  getMetaDescriptors() {
    // const descriptors: MetaDescriptor[] = [];
    Array.from(this.moduleService.watcherService._constructors.keys())
      // .filter(
      //   key =>
      //     this.moduleService.watcherService.getConstructor(key)['type'][
      //       'metadata'
      //     ]['type'] === 'component'
      // )
      .map(
        key => this.moduleService.watcherService.getConstructor(key) as unknown
      )
      .forEach((map) => {
        // console.log(map);
        // return        Array.from(map.type._descriptors.keys())
        // .map(k => map.type._descriptors.get(k))
        // .map(d => d.value)
        // .forEach(v => descriptors.push({ descriptor: v, self: map.value }))
      }

      );
    // return descriptors;
  }
}
