import { Injectable, Inject } from '@rxdi/core';
import {
  CanActivateContext,
  CanActivateCommands,
  CanActivateResolver,
  CanActivateRedirectResult,
} from '@rxdi/router';
import { SocialService } from './core/social/social.service';

@Injectable()
export class LoggedInGuard implements CanActivateResolver {
  @Inject(SocialService) private socialService: SocialService;

  canActivate(context: CanActivateContext, commands: CanActivateCommands) {
    return this.socialService.checkLoggedIn();
  }
}
