import { html, Component, LitElement, css, property, async, TemplateResult } from '@rxdi/lit-html';
import { projectSharedCss } from '../../projects/list/style.css';
import { MaterialBoxCSS } from '../styles/material-box';
import { of, isObservable, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * @customElement info-card-component
 */
@Component({
  selector: 'info-card-component',
  style: css`
    ${projectSharedCss}
    ${MaterialBoxCSS}

    p {
      margin-top: 0px;
      margin-bottom: 5px;
    }
    p.size-13 {
      font-size: 13px;
    }

    .profile-information {
      font-size: 13px;
      color: #0e0e0e;
      font-weight: bold;
      margin-left: 10px;
    }

    .profile-information-container {
      width: auto;
      color: #0e0e0e;
      font-size: 14px;
    }
  `,
  template(this: InfoCardComponent) {
    return html`
      <bootstrap-component></bootstrap-component>
      <card-component>
        <div style="padding: 20px;margin-bottom: 20px;">
          <span style="color: #32435d; font-weight: bold">${this.title}</span>
          <spacer-component></spacer-component>
          ${async(this.template())}
          <slot></slot>
        </div>
      </card-component>
    `;
  },
})
export class InfoCardComponent extends LitElement {
  @property({ attribute: false }) infoItems:
    | Observable<{ key: TemplateResult | string; value: TemplateResult | string }[]>
    | any[] = of([]);
  @property() title: string;

  private template = () =>
    (isObservable(this.infoItems) ? (this.infoItems as Observable<any[]>) : of(this.infoItems)).pipe(
      map(infoItems => {
        return html`
          ${infoItems.length
            ? infoItems.map(
                item => html`
                  <div class="size-13" style="margin-top: 10px;">
                    <div class="flex">
                      <div class="profile-information-container">
                        ${item.key}
                      </div>
                      <div class="profile-information">
                        ${item.value}
                      </div>
                      <span class="spacer"></span>
                    </div>
                  </div>
                `
              )
            : ''}
        `;
      })
    )
}
