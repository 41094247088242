import { css } from '@rxdi/lit-html';

export const style = css`
  .spacer {
    flex: 1 3 auto;
  }
  .center {
    text-align: center;
  }
  .pointer {
    cursor: pointer;
  }
  a {
    cursor: pointer;
    padding: 15px 20px;
    position: relative;
  }
  a:hover {
    color: #b2f5ea;
  }
  a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #c1c9d3;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  a:hover:after {
    width: 100%;
    left: 0;
  }
  @media screen and (max-height: 300px) {
    ul {
      margin-top: 40px;
    }
  }

  h2 {
    font-size: 1.25em;
    cursor: pointer;
  }
  /* .container2 {
    box-shadow: 0 5px 55px rgba(162, 162, 162, 0.25),
      0 10px 10px rgba(0, 0, 0, 0.22);
  } */
  .element {
    -webkit-transition: height 2s; /* For Safari 3.1 to 6.0 */
    transition: height 2s;
  }

  .profile-dropdown:hover {
    opacity: 0.7;
  }
`;
