import { html, Component, css, styleMap } from '@rxdi/lit-html';

/**
 * @customElement app-component
 */
@Component({
  selector: 'app-component',
  style: css`
    navbar-component {
      position: fixed;
      top: 0px;
      background: #1c1f24;
      width: 100%;
      z-index: 11;
    }
  `,
  template(this: AppComponent) {
    return html`
    <div style=${styleMap({'min-height': `${window.innerHeight - 80}px`, 'margin-top': '80px'})}>
      <router-outlet>
        <navbar-component slot="header"></navbar-component>
        <footer-component slot="footer"></footer-component>
      </router-outlet>
      </div>
    `;
  },
  container: document.getElementById('app')
})
export class AppComponent extends HTMLElement {}
