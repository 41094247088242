import { css } from '@rxdi/lit-html';
export const mainViewOffsetTop = 140;
export const MainView = css`
  .view {
    margin: ${mainViewOffsetTop}px auto;
  }
  .flex {
    display: flex;
  }
  .spacer {
    flex: 1 3 auto;
  }
  .pointer {
    cursor: pointer;
  }
`;
