import {
  html,
  Component,
  property,
  css,
  TemplateObservable,
} from '@rxdi/lit-html';
import { Router } from '@rxdi/router';
import { Form, FormGroup } from '@rxdi/forms';
import {
  EmailValidator,
  NetworkErrorValidator,
  PasswordValidator,
  FirebaseAuthValidator,
  FirebasePasswordValidator,
} from '../core/errors/generic-errors';
import { AppErrors } from '../core/errors/errors';
import { InputErrorTemplate } from '../core/errors/error-template';
import { LoginFormModel } from './login.model';
import { BaseComponentWithLoading } from '../shared/loading/loading.component';
import { Inject } from '@rxdi/core';
import { SocialService } from '../core/social/social.service';
import { PrefetchService } from '../core/prefetch/prefetch.service';

/**
 * @customElement login-component
 */
@Component({
  selector: 'login-component',
  style: css`
    .is-invalid {
      background-color: red;
    }
    .input-has-error {
      background-color: white;
    }

    input[type='text'],
    input[type='email'],
    input[type='number'],
    textarea,
    fieldset {
      /* required to properly style form
   elements on WebKit based browsers */
      -webkit-appearance: none;

      width: 100%;
      border: 1px solid #333;
      margin: 0;

      font-family: inherit;
      font-size: 90%;

      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    input:invalid {
      box-shadow: 0 0 5px 1px #37ab9b;
    }

    input:focus:invalid {
      box-shadow: none;
    }
    .container {
      padding: 50px;
      margin: 0px auto;
      margin-top: 150px;
      margin-bottom: 100px;
      width: 600px;
      background-color: #282d32;
      border: 1px solid #3c3c3c;
      border-radius: 15px;
      box-shadow: rgba(162, 162, 162, 0.25) 0px 0px 3px,
        rgba(0, 0, 0, 0.22) 0px 0px 3px;
    }
  `,
  template(this: LoginComponent) {
    return html`
      <inject-tailwind></inject-tailwind>
      <bootstrap-component></bootstrap-component>
      ${this.getLoadingTemplate()}
      <div class="container">
        <div class="form-group card card-container">
          <div style="text-align: center; margin-bottom: 20px;">
            <p style="font-weight: 400;font-size: 2em">
              Welcome Back
            </p>
            <p>
              Need an Account?
              <a
                style="cursor: pointer"
                @click=${() => this.router.go('/signup')}
                >Sign up here.</a
              >
            </p>
          </div>

          <form
            name="login-form"
            class="form-signin ${!this.form.valid ? 'has-error' : ''}"
            novalidate
            @submit=${() => !this.hasErrors() && this.onSubmit()}
          >
            <p>Email</p>
            <input
              name="email"
              type="email"
              value=${this.model.email}
              class="form-control"
              placeholder="Email address"
              required
              autofocus
            />
            <div style="height: 20px;">${InputErrorTemplate(this.form.get('email'))}</div>
            <p >Password</p>
            <input
              type="password"
              value=${this.model.password}
              name="password"
              class="form-control"
              placeholder="Password"
              required
            />
            <div style="height: 20px;">${InputErrorTemplate(this.form.get('password'))}</div>
            <div id="remember" class="checkbox">
              <label>
                <input
                  name="rememberMe"
                  type="checkbox"
                  required
                />
                Trust this computer for 30 days
              </label>
              <div style="height: 20px;">${InputErrorTemplate(this.form.get('rememberMe'))}</div>
            </div>
            <button
              @mouseenter=${() => this.prefetch.fetch(import('../projects/projects.module'))}
              class="btn btn-lg btn-primary btn-block btn-signin"
              type="submit"
            >
              Sign in
            </button>
          </form>
          <a href="/reset-password">
            Forgot the password?
          </a>
        </div>
      </div>
    `;
  },
})
export class LoginComponent extends BaseComponentWithLoading {
  @property() error: any;
  @Router() private router: Router;

  @Inject(SocialService) private socialService: SocialService;

  @Inject(PrefetchService) private prefetch: PrefetchService;

  @property() private model = new LoginFormModel();

  @Form({
    strategy: 'input',
    name: 'login-form',
    strict: true,
  })
  private form = new FormGroup(
    {
      password: [
        this.model.password,
        [PasswordValidator, FirebasePasswordValidator],
      ],
      email: [
        this.model.email,
        [NetworkErrorValidator, EmailValidator, FirebaseAuthValidator],
      ],
      rememberMe: this.model.rememberMe,
    },
    AppErrors
  );

  onSubmit() {
    if (this.isLoading()) {
      return;
    }
    this.showLoading();
    this.socialService
      .signInWithEmailAndPassword(
        this.form.value.email as any,
        this.form.value.password as any
      )
      .subscribe(
        (data) => {
          this.hideLoading();
          this.router.go('/projects/');
        },
        e => {
          this.hideLoading();
          this.error = e;
          this.form.updateValueAndValidity();
          this.error = '';
        }
      );
  }

  hasErrors() {
    return this.form.updateValueAndValidity().length;
  }

}
