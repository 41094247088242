import { html, Component, LitElement, property, css } from '@rxdi/lit-html';
import { github, facebook, twitter, google } from './icons';

type Providers = 'github' | 'facebook' | 'twitter' | 'google';

/**
 * @customElement social-login-button
 */
@Component({
  selector: 'social-login-button',
  style: css`
    .container {
      border-radius: 5px;
      padding: 10px;
      cursor: pointer;
    }
    .container .icon {
      width: 45px;
    }
    .container .text {
      margin-left: 15px;
      margin-top: 13px;
    }
  `,
  template(this: SocialLoginButtonComponent) {
    return html`
      <div
        @pointerenter=${this.mouseEnter}
        @pointerleave=${this.mouseLeave}
        class="container"
        style="background-color:${this.bgColor}; display:flex; "
      >
        <div class="icon">
          ${this.icons[this.type](this.color)}
        </div>
        <div class="text" style="color: ${this.textColor}">
          <slot></slot>
        </div>
      </div>
    `;
  },
})
export class SocialLoginButtonComponent extends LitElement {
  @property() color = '#ffffff';
  @property() bgColor = '';
  @property() textColor = '#fff';
  @property() active: boolean;
  @property() type: Providers = 'github';

  icons = {
    facebook,
    github,
    twitter,
    google,
  };

  OnInit() {
    if (this.active) {
      this.hover();
    }
  }

  hover() {
    this.color = '#222';
    this.bgColor = '#ffffff';
    this.textColor = '#222';
  }

  leave() {
    this.color = '#FFF';
    this.bgColor = '';
    this.textColor = '#FFF';
  }

  mouseEnter() {
    if (!this.active) {
      this.hover();
    }
  }

  mouseLeave() {
    if (!this.active) {
      this.leave();
    }
  }
}
