import { AppErrors } from './errors';
import { InputErrorMessage } from '@rxdi/forms';
import { LoginComponent } from 'src/app/login/login.component';

export function EmailValidator(
  element: HTMLInputElement
): InputErrorMessage<AppErrors> {
  if (
    !/^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
      element.value
    )
  ) {
    element.classList.add('is-invalid');
    return { key: 'email-validator', message: 'Email is not valid' };
  }
  element.classList.remove('is-invalid');
}

export function NetworkErrorValidator(
  this: LoginComponent,
  element: HTMLInputElement
): InputErrorMessage<AppErrors> {
  if (this.error && this.error.message === 'Network error: Failed to fetch') {
    return { key: 'network', message: 'network-comunication-error' };
  }
}

export function FirebaseAuthValidator(
  this: LoginComponent,
  element: HTMLInputElement
): InputErrorMessage<AppErrors> {
  if (this.error && this.error.code === 'auth/email-already-in-use') {
    return { key: this.error.code, message: this.error.message };
  }
  if (this.error && this.error.code === 'auth/user-not-found') {
    return { key: this.error.code, message: this.error.message };
  }
}
export function FirebasePasswordValidator(
  this: LoginComponent,
  element: HTMLInputElement
): InputErrorMessage<AppErrors> {
  if (this.error && this.error.code === 'auth/wrong-password') {
    return { key: this.error.code, message: this.error.message };
  }
}

export function PasswordValidator(element: HTMLInputElement) {
  if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(element.value)) {
    return {
      key: 'password-invalid',
      message: 'Minimum eight characters, at least one letter and one number',
    };
  }
}

export function NameValidator(element: HTMLInputElement) {
  if (!/^(?!.*\/)((?:.*\\.*)|$)/.test(element.value)) {
    return { key: 'name-invalid', message: 'Invalid project name' };
  }
}
