import { AppStateInterface } from './app.interface';
import { COMPONENTS_DATA } from './shared/grid/data';

export const initialState: AppStateInterface = {
  log: {},
  projects: [],
  // projects: {
  //   entities: {
  //     [id]: project
  //   },
  //   ids: []
  // },
  teams: [],
  templates: [],
  sessions: [],
  components: COMPONENTS_DATA,
};
