import { css } from '@rxdi/lit-html';

export const MaterialBoxCSS = css`
  body {
    background: #e2e1e0;
    text-align: center;
  }

  .card {
    color: #91999a;
    background-color: #fff;
    border-radius: 8px;
    margin: 0px;
  }

  .card-1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.14);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .card-1:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.08), 0 10px 10px rgba(0, 0, 0, 0.08);
  }

  .black {
    box-shadow: 0 14px 28px rgba(233, 233, 233, 0.2), 0 10px 10px rgba(232, 232, 232, 0.18);
  }

  .card-2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .card-3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  .card-4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  .card-5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
`;
