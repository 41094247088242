import { Module } from '@rxdi/core';
import { TailWindComponent } from './tailwind/tailwind.component';
import { DefaultSpinnerComponent } from './spinners/default-spinner.component';
import { OriginalStyle } from './original/original-style.component';
// import { HamburgerClientModule } from '@rxdi/ui-components/hamburger/client/index';
import { CardComponent } from './card/card.component';
import { ModalDialogComponent } from './modal/modal-dialog.component';
import { HamburgerComponent } from './hamburgers/hamburger.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { InfoCardComponent } from './info-card/info-card.component';
import { InfoHeaderCardComponent } from './info-header-card/info-header-card.component';
import { GenericNavbarComponent } from './generic-navbar/generic-navbar.component';
import { TabsComponent } from './tabs/tabs.component';
import { BootstrapComponent } from './bootstrap/bootstrap.component';
import { SpacerComponent } from './spacer/spacer.component';
import { GridComponent } from './grid/grid.component';
import { MarkdownReaderModule } from './markdown';

@Module({
  // imports: [HamburgerClientModule],
  imports: [MarkdownReaderModule],
  components: [
    TailWindComponent,
    DefaultSpinnerComponent,
    OriginalStyle,
    CardComponent,
    ModalDialogComponent,
    HamburgerComponent,
    DropdownComponent,
    InfoCardComponent,
    InfoHeaderCardComponent,
    GenericNavbarComponent,
    TabsComponent,
    BootstrapComponent,
    SpacerComponent,
    GridComponent,
  ],
})
export class SharedModule {}
