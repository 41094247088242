import { css } from '@rxdi/lit-html';
import { MainView } from '../../shared/styles/margin-top';

export const projectSharedCss = css`
  ${MainView}
  .container {
    width: 1000px;
  }
  .text {
    font-size: 18px;
    line-height: 14px;
    padding: 20px;
  }
  .context-info {
    font-size: 16px;
  }
  .top {
    margin-top: 70px;
     margin-bottom: 0px;
  }
  .image-container {
    height: 64px;
    padding-left: 15px;
    padding-top: 5px;
    width: 102.4px;
    margin-top: 10px;
  }
  .project-container {
    margin-top: 20px;
  }
  .project-container:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .image-container img {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .spacer {
    flex: 1 3 auto;
  }
  .flex {
    display: flex;
  }
  .pointer {
    cursor: pointer;
  }

  .container-box {
      width: 1000px;
  }

  @media screen and (max-width: 1000px) {
    .container-box {
      width: 90%;
  }
  }
`;
