import { svg, html } from '@rxdi/lit-html';

export const BackArrow = html`
  <div style="color: rgb(107, 120, 115);">
    ${svg`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" fill="#627773"
width="25px" height="25px" viewBox="0 0 459 459" style="enable-background:new 0 0 25 25;" xml:space="preserve">
<g>
<g id="reply">
 <path d="M178.5,140.25v-102L0,216.75l178.5,178.5V290.7c127.5,0,216.75,40.8,280.5,130.05C433.5,293.25,357,165.75,178.5,140.25z"
   />
</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>`}
  </div>
`;
