import { NavbarComponent } from './navbar.component';
import { html, async, classMap } from '@rxdi/lit-html';
import { profilePicture } from './picture.svg';
// import '@rxdi/ui-components';
import { map } from 'rxjs/operators';
import { notification } from './notification.svg';

export const template = function(this: NavbarComponent) {
  return html`
    <inject-tailwind></inject-tailwind>
    <nav class="container2 flex items-center justify-between flex-wrap bg p-3">
      <span class="spacer"></span>
      <div @click=${() => this.router.go('/')} class="pointer flex items-center flex-shrink-0 text-white mr-6">
        <img class="pointer" style="width: 45px;" src="https://graphql-server.com/assets/images/logo.png" />
        <span style="margin-left: 10px;" class="font-semibold text-xl tracking-tight">Graphql Server</span>
      </div>
      <span class="spacer"></span>
      <div @click=${() => (this.menuOpened = !this.menuOpened)} class="block lg:hidden mr-5">
        <hamburger-component type="3dx" .active=${true}></hamburger-component>
      </div>

      <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        ${this.menuOpened
          ? html`
              ${this.isLogged
                ? html`
                    <a
                      @mouseover=${() => this.prefetch.fetch(import('../teams/teams.module'))}
                      @click=${() => this.router.go('/teams')}
                      class="block mt-4 lg:inline-block lg:mt-0 lg:mr-5  hover:text-white"
                    >
                      <h2>Teams</h2>
                    </a>
                    <a
                      @mouseover=${() => this.prefetch.fetch(import('../projects/projects.module'))}
                      @click=${() => this.router.go('/projects')}
                      class="block mt-4 lg:inline-block lg:mt-0 lg:mr-5  hover:text-white"
                    >
                      <h2>Projects</h2>
                    </a>
                    <a
                      @mouseover=${() => this.prefetch.fetch(import('../ui-components/ui-components.module'))}
                      @click=${() => this.router.go('/ui-components')}
                      class="block mt-4 lg:inline-block lg:mt-0 lg:mr-5  hover:text-white"
                    >
                      <h2>Components</h2>
                    </a>
                    ${async(
                      this.socialService.getUser().pipe(
                        map(
                          u =>
                            html`
                              <dropdown-component
                                style="margin-right: 5px;"
                                @itemClicked=${(event: CustomEvent<'Settings'>) => {}}
                                .menuItems=${['You need to setup your SSL certificate!']}
                              >
                                <div style="margin-right: 10px;margin-top: 3px;">
                                  ${notification('#FFF')}
                                </div>
                              </dropdown-component>

                              <dropdown-component
                                @mouseover=${() => this.prefetch.fetch(import('../profile/profile.module'))}
                                @itemClicked=${(event: CustomEvent<'Settings' | 'Logout'>) => {
                                  if (event.detail === 'Settings') {
                                    this.router.go('/profile/settings');
                                  }
                                  if (event.detail === 'Logout') {
                                    this.socialService.signOut().subscribe(() => this.router.go('/'));
                                  }
                                }}
                                .menuItems=${['Settings', 'Logout']}
                              >
                                <div slot="first-item" style="color: black; padding: 15px;">
                                  <strong
                                    >${u && u.displayName && u.displayName.length > 40
                                      ? u.displayName.match(/\b(\w)/g).join('')
                                      : u.displayName}</strong
                                  >
                                  <span style="color: gray">${u.email}</span>
                                </div>
                                ${u.photoURL
                                  ? html`
                                      <div style="display: flex">
                                        <div class="profile-dropdown">
                                          <span class="flex: 1 3 auto;"></span>
                                          <div>
                                            <img
                                              style="border-radius: 50%;width: 30px; border: 2px solid white;"
                                              src=${u.photoURL}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    `
                                  : html`
                                      ${profilePicture}
                                    `}
                              </dropdown-component>
                            `
                        )
                      )
                    )}
                  `
                : html`
                    <div class="text-sm lg:flex-grow">
                      <!-- <a
                                @click=${() => this.router.go('/documentation/')}
                                class=${classMap(this.buttonsClass)}
                              >
                                <app-button text="DOCUMENTATION"></app-button>
                              </a> -->
                      <!-- <a @click=${() => this.router.go('/documentation/')} class=${classMap(this.buttonsClass)}>
                        <h2>Getting started</h2>
                      </a> -->
                      <a
                        href="https://github.com/Stradivario/gapi"
                        target="_blank"
                        class=${classMap(this.buttonsClass)}
                      >
                        <h2>Github</h2>
                      </a>
                      <a
                        @mouseover=${() => this.prefetch.fetch(import('../roadmap/roadmap.module'))}
                        @click=${() => this.router.go('/roadmap/')}
                        class=${classMap(this.buttonsClass)}
                      >
                        <h2>Roadmap</h2>
                      </a>

                      <a
                        @mouseover=${() => this.prefetch.fetch(import('../pricing/pricing.module'))}
                        @click=${() => this.router.go('/pricing/')}
                        class=${classMap(this.buttonsClass)}
                      >
                        <h2>Pricing</h2>
                      </a>
                      <a
                        @mouseover=${() => this.prefetch.fetch(import('../about/about.module'))}
                        @click=${() => this.router.go('/about/')}
                        class=${classMap(this.buttonsClass)}
                      >
                        <h2>About</h2>
                      </a>
                      <!-- <a
                                @click=${() => this.router.go('/release-notes/')}
                                class=${classMap(this.buttonsClass)}
                              >
                                <app-button text="RELEASE NOTES"></app-button>
                              </a> -->
                      <!-- <a
                                @click=${() => this.router.go('/ui-components/')}
                                class="block mt-4 lg:inline-block lg:mt-0  hover:text-white"
                              >
                                <app-button text="UI COMPONENTS"></app-button>
                              </a> -->
                      <!-- <a
                                @click=${() => this.router.go('/about/')}
                                class="block mt-4 lg:inline-block lg:mt-0  hover:text-white"
                              >
                                <app-button text="ABOUT"></app-button>
                              </a> -->
                      <span class="spacer"></span>
                    </div>
                    <a @click=${() => this.router.go('/login/')} class=${classMap(this.buttonsClass)}>
                      <h2>Login</h2>
                    </a>
                    <a
                      style="margin-left: 10px;"
                      @click=${() => this.router.go('/signup/')}
                      class=${classMap(this.buttonsClass)}
                    >
                      <h2>Signup</h2>
                    </a>
                  `}
            `
          : ''}
      </div>
    </nav>
  `;
};
