function strEnum<T extends string>(o: Array<T>): { [K in T]: K } {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
}
export const AppErrors = strEnum([
  'email-validator',
  'network',
  'auth/user-not-found',
  'auth/email-already-in-use',
  'auth/wrong-password',
]);
export type AppErrors = keyof typeof AppErrors;
