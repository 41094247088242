import {
  Component,
  LitElement,
  html,
  css,
  property,
  async,
  TemplateResult,
  styleMap,
} from '@rxdi/lit-html';
import { Inject } from '@rxdi/core';
import { ModalService } from './modal.service';
import { tap } from 'rxjs/operators';

/**
 * @customElement modal-dialog-component
 */
@Component({
  selector: 'modal-dialog-component',
  style: css`
    /* The Modal (background) */
    .modal {
      position: fixed; /* Stay in place */
      z-index: 1000; /* Sit on top */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: rgb(0, 0, 0); /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    }

    /* Modal Content/Box */
    .modal-content {
      background-color: #fefefe;
      margin: 0 auto; /* 15% from the top and centered */
      padding: 20px;
      color: black;
      border: 1px solid #888;
      width: 80%; /* Could be more or less, depending on screen size */
    }

    /* The Close Button */
    .close {
      color: #aaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
    }

    .close:hover,
    .close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  `,
  template(this: ModalDialogComponent) {
    return html`
      ${this.showModal
        ? html`
            <div
              @click=${() =>
                this.closeOnOutsideClick ? (this.showModal = false) : ''}
              class="modal"
            >
              <!-- Modal content -->
              <div class="modal-content" style=${styleMap({
                width: this.width,
                'margin-top': this.marginTop
              })}>
                <span @click=${this.onClose} class="close">&times;</span>
                <div>${async(this.modalTemplate)}</div>
                <slot></slot>
              </div>
            </div>
          `
        : ''}
    `;
  },
})
export class ModalDialogComponent extends LitElement {
  @property({
    type: Boolean,
  })
  showModal = false;
  @property()
  width = '50%';

  @property()
  marginTop = '10%';

  @property({
    type: Boolean,
  })
  closeOnOutsideClick = false;

  @Inject(ModalService) private modalService: ModalService;
  private modalObservable = this.modalService.modalOpened.pipe(
    tap(opened => {
      if (opened) {
        this.showModal = true;
      } else {
        this.showModal = false;
      }
    })
  );

  private modalTemplate = this.modalService.template;

  OnInit() {
    this.modalObservable.subscribe();
  }

  onClose() {
    this.showModal = false;
  }
}
