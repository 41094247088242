export const COMPONENTS_DATA = [
  {
    description: `
An evolving set of free, open source web components for building mobile and desktop web
applications in modern browsers.
`,
    id: '1',
    items: 51,
    namespace: '1',
    owner: 'HTMLELements',
  },
  {
    description: `
An evolving set of free, open source web components for building mobile and desktop web
applications in modern browsers.
`,
    id: '1',
    items: 51,
    namespace: '2',
    owner: 'HTMLELements',
  },
  {
    description: `
An evolving set of free, open source web components for building mobile and desktop web
applications in modern browsers.
`,
    id: '1',
    items: 51,
    namespace: '3',
    owner: 'HTMLELements',
  },
  {
    description: `
An evolving set of free, open source web components for building mobile and desktop web
applications in modern browsers.
`,
    id: '1',
    items: 51,
    namespace: '4',
    owner: 'HTMLELements',
  },
  {
    description: `
An evolving set of free, open source web components for building mobile and desktop web
applications in modern browsers.
`,
    id: '1',
    items: 51,
    namespace: '5',
    owner: 'HTMLELements',
  },
  {
    description: `
An evolving set of free, open source web components for building mobile and desktop web
applications in modern browsers.
`,
    id: '1',
    items: 51,
    namespace: '6',
    owner: 'HTMLELements',
  },
  {
    description: `
An evolving set of free, open source web components for building mobile and desktop web
applications in modern browsers.
`,
    id: '1',
    items: 51,
    namespace: '7',
    owner: 'HTMLELements',
  },
  {
    description: `
An evolving set of free, open source web components for building mobile and desktop web
applications in modern browsers.
`,
    id: '1',
    items: 51,
    namespace: '8',
    owner: 'HTMLELements',
  },
  {
    description: `
An evolving set of free, open source web components for building mobile and desktop web
applications in modern browsers.
`,
    id: '1',
    items: 51,
    namespace: '9',
    owner: 'HTMLELements',
  },
];
