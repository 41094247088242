import { Component } from '@rxdi/lit-html';
import { style } from './original-style.component.css';

/**
 * @customElement original-style-graphql
 */
@Component({
  selector: 'original-style-graphql',
  style,
})
export class OriginalStyle extends HTMLElement {}
