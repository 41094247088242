import { html, Component, LitElement } from '@rxdi/lit-html';

/**
 * @customElement 'spacer-component'
 */
@Component({
  selector: 'spacer-component',
  template(this: SpacerComponent) {
    return html`
      <div style="margin-top: 10px;border-bottom: 1px solid #f4f7f9; text-align: left"></div>
    `;
  },
})
export class SpacerComponent extends LitElement {}
