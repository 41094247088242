import { action, fsa } from '@reactive-redux/store';
import { IProjectType, ITeamType, ISessionType } from '@graphql/introspection';
import { IComponentsType } from './app.interface';

export enum ProjectActions {
  ADD_PROJECT = '[Todo] Add project item',
  REMOVE_PROJECT = '[Todo] Remove project item',
  LOAD_PROJECTS = '[Todo] Load project items',
}

export enum TeamActions {
  ADD_TEAM = '[Backend] Add team item',
  REMOVE_TEAM = '[Backend] Remove team item',
  LOAD_TEAMS = '[Backend] Load team items',
}
export enum ComponentActions {
  ADD_COMPONENT = '[Backend] Add component item',
  REMOVE_COMPONENT = '[Backend] Remove component item',
  LOAD_COMPONENTS = '[Backend] Load component items',
}

export enum SessionActions {
  ADD_SESSION = '[Backend] Add session item',
  REMOVE_SESSION = '[Backend] Remove session item',
  LOAD_SESSIONS = '[Backend] Load session items',
}

// Project actions
export const loadProjects = action(ProjectActions.LOAD_PROJECTS, fsa<IProjectType[]>());
export const addProject = action(ProjectActions.ADD_PROJECT, fsa<IProjectType>());
export const removeProject = action(ProjectActions.REMOVE_PROJECT, fsa<IProjectType>());

// Team actions
export const loadTeams = action(TeamActions.LOAD_TEAMS, fsa<ITeamType[]>());
export const addTeam = action(TeamActions.ADD_TEAM, fsa<ITeamType>());
export const removeTeam = action(TeamActions.REMOVE_TEAM, fsa<ITeamType>());

// Session actions
export const loadSessions = action(SessionActions.LOAD_SESSIONS, fsa<ISessionType[]>());
export const addSession = action(SessionActions.ADD_SESSION, fsa<ISessionType>());
export const removeSession = action(SessionActions.REMOVE_SESSION, fsa<ISessionType>());

// Components actions
export const loadComponents = action(ComponentActions.LOAD_COMPONENTS, fsa<IComponentsType[]>());
export const addComponent = action(ComponentActions.ADD_COMPONENT, fsa<IComponentsType>());
export const removeComponent = action(ComponentActions.REMOVE_COMPONENT, fsa<IComponentsType>());
