import { Injectable } from '@rxdi/core';
import { Subject, BehaviorSubject, of, iif } from 'rxjs';
import {
  TemplateResult,
  TemplateObservable,
  async,
  LitElement,
} from '@rxdi/lit-html';
import { mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class ModalService {
  modalOpened: Subject<boolean> = new Subject();
  template: BehaviorSubject<string> = new BehaviorSubject('');

  showModal(template?: TemplateResult) {
    if (template) {
      this.template.next(template.getHTML());
    }
    this.modalOpened.next(true);
  }
  closeModal() {
    this.modalOpened.next(false);
  }

  getModalTemplate(template: TemplateResult) {
    return async(
      this.modalOpened.pipe(mergeMap(v => iif(() => v, of(template), of(''))))
    );
  }
}
