import { css } from '@rxdi/lit-html';

export const style = css`
  .timeline {
    padding: 5%;
    position: relative;
  }
  .timeline::before {
    content: '';
    background: #c5cae9;
    width: 5px;
    height: 95%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .timeline-item {
    width: 100%;
    margin-bottom: 70px;
  }
  .timeline-item:nth-child(even) .timeline-content {
    float: right;
    padding: 40px 34px 10px 30px;
  }
  .timeline-item:nth-child(even) .timeline-content .date {
    right: auto;
    left: 0;
  }
  .timeline-item:nth-child(even) .timeline-content::after {
    content: '';
    position: absolute;
    border-style: solid;
    width: 0;
    display: none;
    height: 0;
    top: 30px;
    left: -15px;
    border-width: 10px 15px 10px 0;
    border-color: transparent #f5f5f5 transparent transparent;
  }
  .timeline-item::after {
    content: '';
    display: block;
    clear: both;
  }
  .timeline-content {
    position: relative;
    width: 40%;
    padding: 30px 43px !important;
    margin-bottom: 50px;
    border-radius: 6px;
    background: transparent;
    box-shadow: none;
    min-height: 10em;
  }
  .timeline-content::after {
    content: '';
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 30px;
    right: -15px;
    display: none;
    border-width: 10px 0 10px 15px;
    border-color: transparent transparent transparent #f5f5f5;
  }
  .timeline-img {
    background: #3f51b5;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    width: 60px;
    height: 60px;
    margin-left: -30px;
    margin-top: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .timeline-img p {
    height: 0;
    line-height: 1;
    color: #fff;
  }
  .timeline-card {
    padding: 0 !important;
  }
  .timeline-card p {
    padding: 0 20px;
  }
  .timeline-card a {
    margin-left: 20px;
  }
  .timeline-item:nth-child(2) .timeline-img-header {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
      url('https://hd.unsplash.com/photo-1458530970867-aaa3700e966d') center center no-repeat;
    background-size: cover;
  }
  .timeline-item:nth-child(5) .timeline-img-header {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
      url('https://hd.unsplash.com/photo-1444093826349-9ce8c622f4f3') center center no-repeat;
    background-size: cover;
  }
  .timeline-item:nth-child(6) .timeline-img-header {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
      url('https://hd.unsplash.com/photo-1471479917193-f00955256257') center center no-repeat;
    background-size: cover;
  }
  .timeline-item:nth-child(8) .timeline-img-header {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
      url('https://hd.unsplash.com/photo-1466840787022-48e0ec048c8a') center center no-repeat;
    background-size: cover;
  }
  .timeline-item:nth-child(10) .timeline-img-header {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
      url('https://hd.unsplash.com/photo-1447639703758-f525f36456bf') center center no-repeat;
    background-size: cover;
  }
  .timeline-item:nth-child(11) .timeline-img-header {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
      url('https://hd.unsplash.com/photo-1469429978400-082eec725ad5') center center no-repeat;
    background-size: cover;
  }
  .timeline-img-header {
    height: 200px;
    position: relative;
    margin-bottom: 20px;
  }
  .timeline-img-header h2 {
    color: #fff;
    position: absolute;
    bottom: 5px;
    left: 20px;
  }
  blockquote {
    margin-top: 30px;
    color: #757575;
    border-left-color: #3f51b5;
    padding: 0 20px;
  }
  .date {
    background: #3f51b5;
    display: inline-block;
    color: #fff;
    padding: 5px;
    position: absolute;
    top: 0;
    right: 0;
  }
  @media screen and (max-width: 770px) {
    .date {
      right: 0 !important;
      left: 0;
    }
  }
  .leftRadius {
    border-top-left-radius: 6px;
  }
  @media screen and (max-width: 770px) {
    .leftRadius {
      border-radius: 0;
    }
  }
  .rightRadius {
    border-top-right-radius: 6px;
  }
  @media screen and (max-width: 770px) {
    .rightRadius {
      border-radius: 0;
    }
  }
  @media screen and (max-width: 768px) {
    .timeline::before {
      left: 50px;
    }
    .timeline .timeline-img {
      left: 50px;
    }
    .timeline .timeline-content {
      max-width: 100%;
      width: auto;
      margin-left: 70px;
    }
    .timeline .timeline-item:nth-child(even) .timeline-content {
      float: none;
    }
    .timeline .timeline-item:nth-child(odd) .timeline-content::after {
      content: '';
      position: absolute;
      border-style: solid;
      width: 0;
      height: 0;
      top: 30px;
      left: -15px;
      border-width: 10px 15px 10px 0;
      border-color: transparent #f5f5f5 transparent transparent;
    }
  }
  .test {
    background-color: #46bea3;
    border: 0.25rem solid #fff;
    border-radius: 50%;
    -webkit-box-shadow: inset 0px 3px 2px 1px rgba(0, 0, 0, 0.2), 0px 5px 20px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0px 3px 2px 1px rgba(0, 0, 0, 0.2), 0px 5px 20px rgba(0, 0, 0, 0.2);
    color: #fff;
    display: inline-block;
    line-height: 4;
    font-weight: bold;
    height: 4rem;
    left: 50%;
    margin-left: -2.25rem;
    text-align: center;
    position: absolute;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    width: 4rem;
  }
  @media screen and (max-width: 768px) {
    .test {
      left: 50px;
    }
  }
  .timeline-item:nth-child(even) .timeline-content {
    padding: 40px 43px !important;
  }
`;
