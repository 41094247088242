import { html, Component, LitElement, css, property } from '@rxdi/lit-html';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * @customElement dropdown-component
 */
@Component({
  selector: 'dropdown-component',
  style: css`
    .dropbtn {
      background-color: #3498db;
      color: white;
      padding: 16px;
      font-size: 16px;
      border: none;
      cursor: pointer;
    }

    .dropbtn:hover,
    .dropbtn:focus {
      background-color: #2980b9;
    }

    .dropdown {
      position: relative;
      display: inline-block;
      transition: all 0.5s ease;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f1f1f1;
      min-width: 160px;
      overflow: auto;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }

    .dropdown-content .item {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }

    .dropdown .item:hover {
      background-color: #ddd;
    }

    .show {
      display: block;
    }
  `,
  template(this: DropdownComponent) {
    return html`
      <div class="dropdown" @mouseenter=${() => (this.entered = true)} @mouseleave=${() => (this.entered = false)}>
        <div style="cursor: pointer;" @click=${this.onDropdownClicked}>
          <slot></slot>
        </div>
        ${this.isOpened
          ? html`
              <div class="dropdown-content show">
                <slot name="first-item"></slot>
                ${this.menuItems.map(
                  i =>
                    html`
                      <div
                        class="item"
                        style="cursor: pointer"
                        @click=${() => {
                          this.dispatchEvent(new CustomEvent('itemClicked', { detail: i }));
                          this.isOpened = false;
                        }}
                      >
                        ${i}
                      </div>
                    `
                )}
                <slot name="last-item"></slot>
              </div>
            `
          : ''}
      </div>
    `;
  },
})
export class DropdownComponent extends LitElement {
  @property({ type: Boolean }) isOpened = false;
  @property({ type: Array }) menuItems = [];

  @property({ type: Object }) defaultTemplate = html`
    <button class="dropbtn">
      Dropdown
    </button>
  `;

  private entered: boolean;

  OnInit() {
    fromEvent(document, 'click')
      .pipe(filter(() => !this.entered))
      .subscribe(() => (this.isOpened = false));
  }

  private onDropdownClicked(event) {
    this.isOpened = !this.isOpened;
    this.dispatchEvent(new CustomEvent('buttonClicked'));
  }
}
