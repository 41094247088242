import { Injectable, Inject } from '@rxdi/core';
import { AppState } from '../../../app.state';
import { removeProject, addProject, loadProjects } from '../../../actions';
import { IProjectType } from '@graphql/introspection';
import { map, tap, filter, mergeMap, catchError } from 'rxjs/operators';
import { AddProjectModel } from '../../../projects/list/add-project-dialog.component';
import { Subscription } from 'rxjs';
import { BaseGraphqlService } from '../base';
import { ofType } from '@reactive-redux/store';
import { EMPTY, of } from 'rxjs';

@Injectable()
export class ProjectsService  extends BaseGraphqlService {
  @Inject(AppState)
  private store: AppState;

  $projects = this.store.select(state => state.projects);
  // loadProjects$ = this.store.actions$.pipe(
  //   ofType('[Todo] Load project items'),
  //   mergeMap(() => of(true)
  //     .pipe(
  //       tap((data) => {
  //         debugger;
  //       }),
  //       map(projects => ({ type: '[Movies API] Movies Loaded Success', payload: projects })),
  //       catchError(() => EMPTY)
  //     ))
  //   );
  private projectPubSub = this.subscribeToProjectCreation().pipe(
    tap(p => this.update(p))
  );
  private projectSubscription: Subscription;
  OnInit() {
    // this.loadMovies$.subscribe(stream => {
    //   debugger;
    // });
  }

  subscribeToPubSub() {
    this.projectSubscription = this.projectPubSub.subscribe();
  }

  unsubscribeToPubSub() {
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
  }

  update(project: IProjectType) {
    this.remove(project);
    this.add(project);
  }

  load(projects: IProjectType[]) {
    this.store.dispatch(loadProjects(projects));
  }

  add(project: IProjectType) {
    this.store.dispatch(addProject(project));
  }

  remove(project: IProjectType) {
    this.store.dispatch(removeProject({ id: project.id }));
  }

  get(project: IProjectType) {
    return this.$projects.pipe(
      map(types => types.find((p) => p.id === project.id),
      filter((res) => !!res)
    ));
  }

  getProject({id}: IProjectType) {
    return this.query({
      query: 'get-project.query.graphql',
      variables: {
        id,
      },
    }).pipe(map(res => res.data.getProject), tap(res => this.add(res)));
  }

  listProjects() {
    return this.query({
      query: 'list-projects.query.graphql',
      // fetchPolicy: 'network-only',
    }).pipe(
      map(res => res.data.listProjects),
      tap(projects => this.load(projects))
    );
  }

  createProject({ name, repoLink, teamId, containerPassword }: AddProjectModel) {
    return this.mutate({
      mutation: 'create-project.mutation.graphql',
      variables: { name, repoLink, teamId, containerPassword },
    }).pipe(
      map(res => res.data.createProject),
      tap(p => this.add(p))
    );
  }

  deleteProject({id}: IProjectType) {
    return this.mutate({
      mutation: 'delete-project.mutation.graphql',
      variables: { id },
    })
      .pipe(
        map(res => res.data.deleteProject),
        tap(() => this.remove({id}))
      );
  }

  private subscribeToProjectCreation() {
    return this.subscribe({
      query: 'create-project.subscription.graphql',
    }).pipe(map(res => res.data.subscribeToCreateProject));
  }
}
