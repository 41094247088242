import { html, TemplateObservable } from '@rxdi/lit-html';
import { BaseComponent } from '../base.component';
import { BehaviorSubject, iif, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export class BaseComponentWithLoading extends BaseComponent {
  loading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @TemplateObservable()
  private loadingTemplate = this.loading.pipe(
    mergeMap(v =>
      iif(
        () => v,
        of(
          html`
            <loading-screen-component></loading-screen-component>
          `
        ),
        of('')
      )
    )
  );

  showLoading() {
    this.loading.next(true);
  }

  hideLoading() {
    this.loading.next(false);
  }

  getLoadingTemplate() {
    return this.loadingTemplate;
  }

  isLoading() {
    return this.loading.getValue();
  }
}
