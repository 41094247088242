import { Module } from '@rxdi/core';
// import { MarkdownReaderModule } from '@rxdi/ui-components/markdown-reader/client';
import { ResponsiveService } from '@rxdi/ui-components/services';
import { SocialModule } from './social/social.module';
import { ProjectsService } from './services/projects/projects.service';
import { TeamsService } from './services/teams/teams.service';
import { UserService } from './services/user/user.service';
import { CLIService } from './services/cli/cli.service';
import { UIComponentsService } from './services/ui-components/ui-components.service';
import { FooterService } from './services/footer/footer.service';
import { FadeinPlugin } from './fadein/fadein.plugin';
import { VerticalTimelineModule } from './vertical-timeline/vertical-timeline.module';
import { PrefetchService } from './prefetch/prefetch.service';
import { VersionService } from './version/version.service';

@Module({
  imports: [
    // MarkdownReaderModule,
    SocialModule.forRoot({
      apiKey: 'AIzaSyCMakkFDsMzImECr91_iPeM5LQmWvQ30ok',
      authDomain: 'graphql-server-2ea53.firebaseapp.com',
      databaseURL: 'https://graphql-server-2ea53.firebaseio.com',
      projectId: 'graphql-server-2ea53',
      storageBucket: 'graphql-server-2ea53.appspot.com',
      messagingSenderId: '1023512172807',
      appId: '1:1023512172807:web:715dec1d8c695a55',
    }),
    VerticalTimelineModule,
  ],
  providers: [
    ResponsiveService,
    ProjectsService,
    TeamsService,
    UserService,
    CLIService,
    UIComponentsService,
    FooterService,
    PrefetchService,
    VersionService,
  ],
  plugins: [FadeinPlugin]
})
export class CoreModule {}
