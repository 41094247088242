import { Module, ModuleWithProviders } from '@rxdi/core';
// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from 'firebase/app';

// Add the Firebase services that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import { FirebaseSocialConfig } from './social.injections';
import { SocialLoginButtonComponent } from './components/github/social-button.component';
import { SocialService } from './social.service';

@Module({
  components: [SocialLoginButtonComponent],
  providers: [SocialService],
})
export class SocialModule {
  public static forRoot(config: FirebaseSocialConfig): ModuleWithProviders {
    return {
      module: SocialModule,
      providers: [
        {
          provide: 'firebase',
          useFactory: () => {
            firebase.initializeApp(config);
            return firebase;
          },
        },
      ],
    };
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'social-login-button': SocialLoginButtonComponent;
  }
}
