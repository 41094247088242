import { html, Component, LitElement, css } from '@rxdi/lit-html';
import { Inject } from '@rxdi/core';
import { SocialService } from '../../core/social/social.service';
import { Router } from '@rxdi/router';
import { switchMap } from 'rxjs/operators';
import { BaseComponent } from '../../shared/base.component';

/**
 * @customElement social-login-container
 */
@Component({
  selector: 'social-login-container',
  style: css`
    .button {
      padding-bottom: 10px;
    }
  `,
  template(this: SocialComponent) {
    return html`
      <div class="button">
        <social-login-button type="github" @click=${this.loginWithGithub}>
          Login with GitHub
        </social-login-button>
      </div>
    `;
  },
})
export class SocialComponent extends BaseComponent {
  @Inject(SocialService)
  private socialService: SocialService;

  @Router()
  private router: Router;

  private loginWithGithub() {
    this.socialService
      .signInWithGithub(provider => {
        provider.addScope('repo');
        provider.setCustomParameters({
          allow_signup: 'false',
        });
        return provider;
      })
      .pipe(
        switchMap(() => this.mutate({ mutation: 'create-user-mutation.graphql' }))
      )
      .subscribe(this.goToProjects);
  }

  private goToProjects = (data) => {
    this.router.go('/projects');
  }

  private signInWithGoogle() {
    this.socialService.signInWithGoogle().subscribe(this.goToProjects);
  }
}
