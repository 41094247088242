import { html, Component, LitElement, css } from '@rxdi/lit-html';
import { roadmap } from './content.svg';

/**
 * @customElement root-component
 */
@Component({
  selector: 'root-component',
  template(this: RootComponent) {
    return html`
      <div style="margin-top:100px;margin-bottom: 20px;">
        <div></div>
      </div>

      <flex-grid
        .$items=${[
          html`
            <div>
              <h1 style="font-size: 2.75em; font-weight: 700; width: 400px; line-height: 1.25em;">
                Your Open-Source Scalable Cloud Solution
              </h1>
              <div style="height:20px;"></div>
              <h2>Get the latest from Graphql Server</h2>
              <p>Receive news, announcements, and more.</p>
            </div>
          `,
          html`
            <div style="width: 596px;">${roadmap}</div>
          `,
        ]}
      >
      </flex-grid>
    `;
  },
})
export class RootComponent extends LitElement {}
