import { Component, html, LitElement, css, property } from '@rxdi/lit-html';
import { MaterialBoxCSS } from '../styles/material-box';

/**
 * @customElement card-component
 */
@Component({
  selector: 'card-component',
  style: css`
    ${MaterialBoxCSS}
  `,
  template(this: CardComponent) {
    return html`
      <div class="card ${this.shadow}${this.invert ? ' black' : ''}">
        <slot></slot>
      </div>
    `;
  },
})
export class CardComponent extends LitElement {
  @property({ type: String }) shadow: 'card-1' | 'card-2' | 'card-3' | 'card-4' | 'card-5' = 'card-1';
  @property({ type: Boolean }) invert: boolean;
}
