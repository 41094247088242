import { Router } from '@rxdi/router';
import { property, eventOptions, LitElement, Component } from '@rxdi/lit-html';
import './buttons/buttons.component';
import { template } from './navbar.component.html';
import { style } from './navbar.component.css';
import { Inject } from '@rxdi/core';
import { ResponsiveService } from '@rxdi/ui-components/services';
import { SocialService } from '../core/social/social.service';
import { tap, filter } from 'rxjs/operators';
import { PrefetchService } from '../core/prefetch/prefetch.service';

/**
 * @customElement navbar-component
 */
@Component({
  selector: 'navbar-component',
  style,
  template,
})
export class NavbarComponent extends LitElement {
  @property({ type: Number }) counter = 0;
  @property({ type: Boolean }) menuOpened = true;
  @property({ type: Boolean }) isLogged = false;
  @Router() router: Router;

  @Inject(PrefetchService)
  public prefetch: PrefetchService;

  public buttonsClass = {
    block: true,
    'mt-4': true,
    'lg:inline-block': true,
    'lg:mt-0': true,
    'hover:text-white': true,
    'mr-5': true,
    center: true,
  };

  @Inject(SocialService) socialService: SocialService;
  @Inject(ResponsiveService) private responsive: ResponsiveService;

  public isLoggedSubscription = this.socialService.isLoggedIn.asObservable().pipe(tap(u => (this.isLogged = !!u)));

  combination = this.responsive.combineBoth();
  private mobileView = this.combination.pipe(
    filter(({ width }) => width < 1000),
    tap(() => (this.menuOpened = false))
  );
  private desktopView = this.combination.pipe(
    filter(({ width }) => width > 1050),
    tap(() => (this.menuOpened = true))
  );
  OnInit() {
    this.mobileView.subscribe();
    this.desktopView.subscribe();
    this.isLoggedSubscription.subscribe();
  }

  @eventOptions({ capture: true })
  onIncrement(e: Event) {
    this.counter++;
  }

  @eventOptions({ capture: true })
  onDecrement(e: Event) {
    this.counter--;
  }
}
