import { Injectable, Inject } from '@rxdi/core';
import { AppState } from '../../../app.state';
import { loadTeams, addTeam, removeTeam } from '../../../actions';
import { ITeamType } from '@graphql/introspection';
import { BaseGraphqlService } from '../base';

@Injectable()
export class TeamsService extends BaseGraphqlService {
  @Inject(AppState)
  private store: AppState;

  $teams = this.store.select(state => state.teams);

  updateTeams(team: ITeamType) {
    this.removeTeam(team);
    this.addTeam(team);
  }

  loadTeams(team: ITeamType[]) {
    this.store.dispatch(loadTeams(team));
  }

  addTeam(team: ITeamType) {
    this.store.dispatch(addTeam(team));
  }

  removeTeam(team: ITeamType) {
    this.store.dispatch(removeTeam({ id: team.id }));
  }
}
