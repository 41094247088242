import { Injectable } from '@rxdi/core';
import { Observable } from 'rxjs';
import { ICLITokenType, IMutation } from '@graphql/introspection';
import { BaseGraphqlService } from '../base';
import { map } from 'rxjs/operators';

@Injectable()
export class CLIService extends BaseGraphqlService {
  generateCLIToken(): Observable<ICLITokenType> {
    return this.mutate<IMutation>({
      mutation: 'generate-cli-token.graphql',
    }).pipe(map(({ data }) => data.generateCLIToken));
  }
  revokeCLIToken(): Observable<ICLITokenType> {
    return this.mutate<IMutation>({
      mutation: 'revoke-cli-token.graphql',
    }).pipe(map(({ data }) => data.revokeCLIToken));
  }
}
