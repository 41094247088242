import { Injectable } from '@rxdi/core';
import packageJson from '../../../../package.json';

@Injectable({ init: true })
export class VersionService {
  getAppVersion(): string {
    return packageJson.version;
  }

  OnInit() {
    console.log(`App version: ${packageJson.version}`);
  }
}
