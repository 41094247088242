import { Module, Container } from '@rxdi/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AppState } from './app.state';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { LoginComponent } from './login/login.component';
import { GraphqlModule } from '@rxdi/graphql-client';
import { isDevelopment } from './environment';
import { DOCUMENTS } from './@introspection/documents';
import { SocialComponent } from './login/social/social.component';
import { SignUpComponent } from './signin/signin.component';
import { GraphQLRequest } from 'apollo-link';
import { RouterModule } from '@rxdi/router';
import { LoggedInGuard } from './app.guards';
import { RootComponent } from './root/root.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import * as firebase from 'firebase/app';
import { ReactiveUiModule } from '@rxdi/ui-kit';
import { GraphModule } from '@rxdi/ui-kit/graph';
import { html } from '@rxdi/lit-html';
import { LoadingComponent } from '@rxdi/ui-kit/loading';

const graphConfig = {
  async onRequest(this: GraphQLRequest) {
    const headers = new Headers();
    headers.append('authorization', await firebase.auth().currentUser.getIdToken());
    return headers;
  },
  uri: isDevelopment()
    ? 'http://localhost:9003/graphql' // http://localhost:5000/graphql-server-2ea53/us-central1/graphql/graphql
    : 'https://pubsub.youvolio.com/graphql', // https://us-central1-graphql-server-2ea53.cloudfunctions.net/graphql/graphql
  pubsub: isDevelopment() ? 'ws://localhost:9000/subscriptions' : 'wss://pubsub.youvolio.com/subscriptions',
};

const defaultTemplates = {
  error: e => {
    return html`
      <p style="color: inherit; text-align: center;">
        ${e}
      </p>
    `;
  },
  loading: () => {
    return html`
      <div style="text-align: center;">
        <rx-loading palette="danger"></rx-loading>
      </div>
    `;
  },
};

@Module({
  components: [
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    LoginComponent,
    SocialComponent,
    SignUpComponent,
    ResetPasswordComponent,
    LoadingComponent,
  ],
  imports: [
    ReactiveUiModule.forRoot(),
    GraphqlModule.forRoot(graphConfig, DOCUMENTS),
    GraphModule.forRoot(graphConfig, defaultTemplates),
    RouterModule.forRoot<string>(
      [
        {
          path: '/',
          component: RootComponent,
          animate: true,
        },
        {
          path: '/about',
          component: HomeComponent,
          animate: true,
        },
        {
          path: '/login',
          component: LoginComponent,
        },
        {
          path: '/signup',
          component: SignUpComponent,
        },

        {
          path: '/ui-components',
          children: () => import('./ui-components/ui-components.module'),
        },
        {
          path: '/roadmap',
          children: () => import('./roadmap/roadmap.module'),
        },
        {
          path: '/documentation',
          children: () => import('./documentation/documentation.module'),
        },
        {
          path: '/release-notes',
          children: () => import('./release-notes/release-notes.module'),
        },
        {
          path: '/reset-password',
          component: ResetPasswordComponent,
        },
        {
          path: '/projects',
          children: () => import('./projects/projects.module'),
          canActivate: LoggedInGuard,
        },
        {
          path: '/profile',
          children: () => import('./profile/profile.module'),
          canActivate: LoggedInGuard,
        },
        {
          path: '/pricing',
          animate: true,
          children: () => import('./pricing/pricing.module'),
        },
        {
          path: '/teams',
          children: () => import('./teams/teams.module'),
          canActivate: LoggedInGuard,
        },
        {
          path: '(.*)',
          animate: true,
          component: 'not-found-component',
          action: () => import('./not-found/not-found.component'),
        },
        //   { path: '/users/:user', component: 'x-user-profile' },
      ],
      { log: true }
    ),
    SharedModule,
    CoreModule,
  ],
  bootstrap: [AppComponent],
  providers: [AppState],
})
export class AppModule {}
