import { html, Component, LitElement, property, css } from '@rxdi/lit-html';
import { projectSharedCss } from '../../projects/list/style.css';
import { MaterialBoxCSS } from '../styles/material-box';

/**
 * @customElement info-header-card-component
 */
@Component({
  selector: 'info-header-card-component',
  style: css`
    ${projectSharedCss}
    ${MaterialBoxCSS}

    p {
      margin-top: 0px;
      margin-bottom: 5px;
    }
    p.size-13 {
      font-size: 13px;
    }

    .profile-information {
      font-size: 13px;
      color: #0e0e0e;
      font-weight: bold;
    }

    .profile-information-container {
      width: 100px;
      color: #0e0e0e;
      font-size: 14px;
    }
    .infoheaderContainer {
      padding: 20px;
      margin-top: 0px;
    }
  `,
  template(this: InfoHeaderCardComponent) {
    return html`
      <card-component>
        <div class="flex">
          <div class="infoheaderContainer" style=${this.styleContainer}>
            <p
              style="margin-top: 0px;margin-bottom: 5px; font-size: 22px; font-weight: bold; color: #324044"
            >
              ${this.headerTitle}
            </p>
            ${this.infoItems.length
              ? html`
                  ${this.infoItems.map(
                    i =>
                      html`
                        <p class="size-13">${i}</p>
                      `
                  )}
                `
              : html`
                  <slot name="items"></slot>
                `}
          </div>
          ${this.photoURL
            ? html`
                <div>
                  <img
                    style="border-radius: 65px; width: 120px; margin-left: 30px;margin-top: 10px;"
                    src=${this.photoURL}
                  />
                </div>
              `
            : html`
                <slot></slot>
              `}
        </div>
      </card-component>
    `;
  },
})
export class InfoHeaderCardComponent extends LitElement {
  @property() headerTitle: string;
  @property() infoItems: string[] = [];
  @property() photoURL: string;
  @property() styleContainer = '';
}
