import { produce } from 'immer';
import { reduceNS, isType } from '@reactive-redux/store';
import { AppStateInterface } from './app.interface';
import { addTeam } from './actions';

export const history = reduceNS<AppStateInterface, any>((state, action) =>
  produce(state, draft => {
    if (isType(action, addTeam)) {
      return;
    }

    const timestamp = new Date().getTime();

    draft.log[timestamp] = {
      action,
      timestamp,
      visible: true,
    };
  })
);

export const dbUpdates = reduceNS<AppStateInterface, any>(
  (state, action) => {
    if (action.type.includes('[Backend]')) {
      // debugger;
    }
    return state;
  }
);
