import { history, dbUpdates } from './transducers';
import { reducerFn } from './reducers';
import { AppStateInterface } from './app.interface';
import { initialState } from './initial.state';
import {
  addProject,
  removeProject,
  loadProjects,
  addTeam,
  removeTeam,
  loadTeams,
  addSession,
  removeSession,
  loadSessions,
  addComponent,
  loadComponents,
  removeComponent
} from './actions';
import { union } from 'ts-action';
import { of, Subject, Observable } from 'rxjs';
import { Injectable } from '@rxdi/core';

const actions = union(
  addProject,
  removeProject,
  loadProjects,
  addTeam,
  removeTeam,
  loadTeams,
  addSession,
  removeSession,
  loadSessions,
  addComponent,
  loadComponents,
  removeComponent
);

import { Action, Store, Reducer } from '@reactive-redux/store';
import { TransducerFn } from '@reactive-redux/store/dist/interfaces';
import { map } from 'rxjs/operators';

export class BaseStore<State, Actions extends Action = any> extends Store<
  State,
  Actions
> {
  constructor(
    public baseConfig: {
      initialState: State;
      reducer: Reducer<State>;
      transducers: TransducerFn<State, any>[];
      actionSubject: Subject<any>;
      destroySubject: Subject<any>;
    }
  ) {
    super({
      actionStream$: baseConfig.actionSubject.asObservable(),
      destroy$: baseConfig.destroySubject.asObservable(),
      initialState$: of(baseConfig.initialState),
      reducer$: of(baseConfig.reducer),
      transducers$: of(baseConfig.transducers || []),
    });
  }

  dispatch(action: Actions) {
    this.baseConfig.actionSubject.next(action);
  }

  destroy() {
    this.baseConfig.destroySubject.next(true);
  }

  select<K>(mapFn: (state: State) => K): Observable<K> {
    return this.state$.pipe(map(mapFn));
  }
}

@Injectable()
export class AppState extends BaseStore<AppStateInterface, typeof actions> {
  static readonly initialState: AppStateInterface = initialState;
  static readonly reducer = reducerFn;
  static readonly transducers = [history, dbUpdates];

  constructor() {
    super({
      initialState: AppState.initialState,
      reducer: AppState.reducer,
      transducers: AppState.transducers,
      actionSubject: new Subject(),
      destroySubject: new Subject(),
    });
  }
}
