import {
  html,
  Component,
  LitElement,
  property,
  css,
  classMap,
} from '@rxdi/lit-html';
import { Router } from '@rxdi/router';

export interface Tab {
  title: string;
  link?: string;
  sub?: Tab[];
  active?: boolean;
}
/**
 * @customElement tabs-component
 */
@Component({
  selector: 'tabs-component',
  style: css`
    li.list-items {
      border-left: 2px solid #3bb0a1;
      background-color: white;
      padding: 8px;
      font-size: 13px;
      font-weight: bold;
      list-style: none;
      user-select: none;
    }
    ul {
      margin: 0px;
      padding-left: 0px;
      border-bottom: 1px solid #e8e8e8;
      user-select: none;
    }

    li.list-items-inactive {
      background-color: #fafbfb;
      padding: 5px;
      padding-left: 15px;
      color: #868f91;
      font-size: 15px;
      list-style: none;
    }
    li.list-items:hover {
      cursor: pointer;
      color: #868f91;
    }
    li.list-items-inactive:hover {
      color: black;
      cursor: pointer;
    }
    .active {
      color: black;
      cursor: pointer;
    }
  `,
  template(this: TabsComponent) {
    return html`
      ${this.menus.map(
        menu => html`
          ${this.getMenus(menu)} ${menu.active ? this.getActiveItems(menu) : ''}
        `
      )}
    `;
  },
})
export class TabsComponent extends LitElement {
  @Router() private router: Router;
  @property() menus: Tab[] = [];

  private getActiveItems(menu: Tab) {
    return html`
      ${menu.sub
        ? menu.sub.map(
            subMenu => html`
              <ul>
                <li
                @click=${() => subMenu.link && this.router.go(subMenu.link)}
                  class=${classMap({
                    'list-items-inactive': true,
                    active: subMenu.active,
                  })}
                >
                  <a
                    style="font-size: 13px;"
                    >${subMenu.title}</a
                  >
                </li>
              </ul>
            `
          )
        : ''}
    `;
  }

  private getMenus(menu: Tab) {
    return html`
      <ul @click=${() => this.toggleMenu(menu)}>
        <li class="list-items">
          ${menu.title}
        </li>
      </ul>
    `;
  }

  private toggleMenu(menu: Tab) {
    menu.active = !menu.active;
    this.menus = [...this.menus];
  }
}
