import { html, Component, LitElement, css, property } from '@rxdi/lit-html';

import { Inject } from '@rxdi/core';
import { SocialService } from '../core/social/social.service';
import { Form, FormGroup } from '@rxdi/forms';
import { AppErrors } from '../core/errors/errors';
import { NetworkErrorValidator, EmailValidator, FirebaseAuthValidator } from '../core/errors/generic-errors';
import { InputErrorTemplate } from '../core/errors/error-template';
import { ResetPasswordModel } from './reset-password.model';

/**
 * @customElement reset-password-component
 */
@Component({
  selector: 'reset-password-component',
  style: css`
    .container {
      padding: 50px;
      margin: 0px auto;
      margin-top: 150px;
      width: 600px;
      background-color: #282d32;
      border: 1px solid #3c3c3c;
      border-radius: 15px;
      box-shadow: rgba(162, 162, 162, 0.25) 0px 0px 3px, rgba(0, 0, 0, 0.22) 0px 0px 3px;
    }
  `,
  template(this: ResetPasswordComponent) {
    return html`
      <inject-tailwind>
        <link href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css" rel="stylesheet" />
      </inject-tailwind>
      <bootstrap-component></bootstrap-component>
      <div class="container">
        <div class="form-group card card-container">
          ${this.emailSend
            ? html`
                <div style="text-align: center;">
                  <p>
                    Success send email to address ${this.form.value.email}
                  </p>
                </div>
              `
            : html`
                <div style="text-align: center;">
                  <p style="font-weight: 400;font-size: 2em;margin-bottom: 20px;">
                    Forgot password ?
                  </p>
                  <p>Enter the email address associated with your account.</p>
                </div>
                <form
                  name="register-form"
                  class="form-signin ${!this.form.valid ? 'has-error' : ''}"
                  novalidate
                  @submit=${() => !this.hasErrors() && this.onSubmit()}
                >
                  <p>Email</p>
                  <input
                    name="email"
                    type="email"
                    class="form-control"
                    placeholder="Email address"
                    required
                    autofocus
                  />
                  ${InputErrorTemplate(this.form.get('email'))}
                  <div style="height:30px"></div>
                  <button class="btn btn-lg btn-primary btn-block btn-signin" type="submit">
                    Reset password
                  </button>
                </form>
              `}
        </div>
      </div>
    `;
  },
})
export class ResetPasswordComponent extends LitElement {
  @Inject(SocialService) private socialService: SocialService;
  @property() private emailSend: boolean;
  model: ResetPasswordModel = new ResetPasswordModel();
  error: any;
  @Form({
    strategy: 'input',
    name: 'register-form',
    strict: true,
  })
  private form = new FormGroup<ResetPasswordModel>({
    email: [this.model.email, [NetworkErrorValidator, EmailValidator, FirebaseAuthValidator]] as any,
  });

  onSubmit() {
    this.socialService.resetPassword(this.form.value.email).subscribe(
      stream => {
        this.emailSend = true;
        debugger;
      },
      e => {
        this.error = e;
        debugger;
      }
    );
  }

  hasErrors() {
    return this.form.updateValueAndValidity().length;
  }
}
