import { Injectable, Inject } from '@rxdi/core';
import { SocialService } from '../../social/social.service';
import { map, switchMap } from 'rxjs/operators';
import { BaseGraphqlService } from '../base';
import { IQuery, IMutation } from '@graphql/introspection';

@Injectable()
export class UserService extends BaseGraphqlService {
  @Inject(SocialService) private socialService: SocialService;
  private cache = true;

  setCache(cache: boolean) {
    this.cache = cache;
  }
  getUser() {
    return this.socialService.getUser().pipe(
      map(res => res.uid),
      switchMap(uid => this.getUserQuery(uid))
    );
  }

  getUserQuery(uid: string) {
    return this.query<IQuery>({
      query: 'get-user.mutation.graphql',
      fetchPolicy: this.cache ? null : 'network-only',
      variables: { uid },
    }).pipe(map(({ data }) => data.getUser));
  }

  saveToken() {
    return this.socialService
      .getUserRef()
      .pipe(switchMap(ref => ref.getIdToken()))
      .pipe(
        switchMap(token =>
          this.mutate<IMutation>({
            mutation: 'save-token.mutation.graphql',
            variables: { token },
          }).pipe(map(({ data }) => data.saveToken.cliToken))
        )
      );
  }
}
