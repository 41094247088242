import { Injectable, Inject } from '@rxdi/core';
import { AppState } from '../../../app.state';
import { addComponent, removeComponent, loadComponents } from '../../../actions';
import { BaseGraphqlService } from '../base';
import { IComponentsType } from '../../../app.interface';

@Injectable()
export class UIComponentsService extends BaseGraphqlService {
  @Inject(AppState)
  private store: AppState;

  $components = this.store.select(state => state.components);

  updateComponents(component: IComponentsType) {
    this.removeComponent(component);
    this.addComponent(component);
  }

  loadComponents(components: IComponentsType[]) {
    this.store.dispatch(loadComponents(components));
  }

  addComponent(component: IComponentsType) {
    this.store.dispatch(addComponent(component));
  }

  removeComponent(component: IComponentsType) {
    this.store.dispatch(removeComponent({ id: component.id }));
  }
}
