import { html, Component, LitElement } from '@rxdi/lit-html';
import { style } from './vertical-timeline.css';

/**
 * @customElement vertical-timeline-component
 */
@Component({
  selector: 'vertical-timeline-component',
  style,
  template(this: VerticalTimelineComponent) {
    return html`
      <section class="timeline">
        <div>
          <slot></slot>
        </div>
      </section>
    `;
  },
})
export class VerticalTimelineComponent extends LitElement {

}
