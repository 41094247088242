import { html, Component, LitElement, property, styleMap, classMap, css } from '@rxdi/lit-html';
import { style } from '../vertical-timeline.css';
import { Inject } from '@rxdi/core';
import { ResponsiveService } from '@rxdi/ui-components/services';

/**
 * @customElement vertical-timeline-card-component
 */
@Component({
  selector: 'vertical-timeline-card-component',
  styles: [style],
  template(this: CardComponent) {
    return html`
      <div class="timeline-img test" style=${styleMap({ background: this.color })}>
        <span style=${styleMap({ color: this.textColor })}>${this.dotContent}</span>
      </div>
      <div class="timeline-content">
        <div
          style=${styleMap({ background: this.color })}
          class=${classMap({
            date: true,
            rightRadius: this.position === 'right',
            leftRadius: this.position === 'left',
          })}
        >
          <span style=${styleMap({ color: this.textColor })}>${this.dateValue.toDateString()}</span>
        </div>

        <div style=${styleMap({ 'margin-top': '20px', 'text-align': this.position === 'right' ? 'right' : 'left' })}>
          <slot></slot>
        </div>
      </div>
    `;
  },
})
export class CardComponent extends LitElement {
  @property({ type: String }) position: string;

  @property({ attribute: false }) dateValue: Date;
  @property() dotContent: string;
  @property({ type: String }) color: string;

  @Inject(ResponsiveService)
  private responsive: ResponsiveService;

  private oldPosition: string;
  private textColor: string;
  OnInit() {
    this.oldPosition = this.position;
    this.responsive.width.subscribe(width => {
      if (width < 600) {
        this.position = 'left';
      } else if (width > 600) {
        this.position = this.oldPosition;
      }
    });
    if (this.dateValue === null || this.dateValue === undefined) {
      this.dateValue = new Date();
    }

    if (this.color === null || this.color === undefined) {
      this.color = '#3F51B5';
    }

    if (this.isLight(this.color)) {
      this.textColor = '#FFFFFF';
    } else {
      this.textColor = '#FFFFFF';
    }
  }

  isLight(hexColor: string): boolean {
    const R = Number.parseInt(hexColor.slice(1, 3), 16);
    const G = Number.parseInt(hexColor.slice(3, 5), 16);
    const B = Number.parseInt(hexColor.slice(5, 7), 16);
    const maxBrightness = this.calculateBrightness(255, 255, 255);
    const brightness = this.calculateBrightness(R, G, B);
    const pBrightness = brightness / maxBrightness;
    return pBrightness > 0.5;
  }

  // HSP rule sqrt( .299 R2 + .587 G2 + .114 B2 ), see http://alienryderflex.com/hsp.html
  private calculateBrightness(R: number, G: number, B: number): number {
    return Math.sqrt(0.299 * Math.pow(R, 2) + 0.587 * Math.pow(G, 2) + 0.114 * Math.pow(B, 2));
  }
}
