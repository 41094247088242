import { produce } from 'immer';
import { AppStateInterface } from './app.interface';
import { reducer, on, createSelector } from '@reactive-redux/store';
import {
  addProject,
  removeProject,
  loadProjects,
  addTeam,
  removeTeam,
  loadTeams,
  addComponent,
  removeComponent,
  loadComponents,
} from './actions';
import { initialState } from './initial.state';

export const reducerFn = reducer<AppStateInterface>(
  initialState,
  on(addProject, (state, { payload }) =>
    produce(state, draft => {
      const { id } = payload;
      draft.projects = state.projects.filter(p => p.id !== id);
      draft.projects.push(payload);
    })
  ),
  on(removeProject, (state: AppStateInterface, { payload }) =>
    produce(state, draft => {
      const { id } = payload;
      draft.projects = draft.projects.filter(p => p.id !== id);
    })
  ),
  on(loadProjects, (state: AppStateInterface, { payload }) =>
    produce(state, draft => {
      draft.projects = payload;
    })
  ),
  on(addTeam, (state, { payload }) =>
    produce(state, draft => {
      const { id } = payload;
      draft.teams = state.teams.filter(p => p.id !== id);
      draft.teams.push(payload);
    })
  ),
  on(removeTeam, (state: AppStateInterface, { payload }) =>
    produce(state, draft => {
      const { id } = payload;
      draft.teams = draft.teams.filter(p => p.id !== id);
    })
  ),
  on(loadTeams, (state: AppStateInterface, { payload }) =>
    produce(state, draft => {
      draft.teams = payload;
    })
  ),

  on(addComponent, (state: AppStateInterface, { payload }) =>
    produce(state, draft => {
      const { id } = payload;
      draft.components = state.components.filter(p => p.id !== id);
      draft.components.push(payload);
    })
  ),
  on(removeComponent, (state: AppStateInterface, { payload }) =>
    produce(state, draft => {
      const { id } = payload;
      draft.components = draft.components.filter(p => p.id !== id);
    })
  ),
  on(loadComponents, (state: AppStateInterface, { payload }) =>
    produce(state, draft => {
      draft.components = payload;
    })
  )
);
