import { html, Component } from '@rxdi/lit-html';

/**
 * @customElement bootstrap-component
 */
@Component({
  selector: 'bootstrap-component',
  template(this: BootstrapComponent) {
    return html`
      <link
        href="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/css/bootstrap.min.css"
        rel="stylesheet"
      />
      <script src="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/js/bootstrap.min.js"></script>
    `;
  },
})
export class BootstrapComponent extends HTMLElement {}
