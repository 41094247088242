import {
  html,
  Component,
  LitElement,
  css,
  property,
  classMap,
} from '@rxdi/lit-html';
import { Router } from '@rxdi/router';

/**
 * @customElement generic-navbar-component
 */
@Component({
  selector: 'generic-navbar-component',
  style: css`
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      cursor: pointer;
    }

    li {
      float: left;
    }

    li a {
      display: block;
      color: #6b7873;
      text-align: center;
      padding: 16px;
      text-decoration: none;
    }

    li a:hover {
      color: white;
    }

    .white {
      color: white;
    }
  `,
  template(this: GenericNavbarComponent) {
    return html`
      <div style="padding: 10px;">
        <ul>
          ${this.items.map(
            i => html`
              <li @click=${() => this.router.go(i.value)}>
                <a
                  class=${classMap({
                    white: this.router.activePath === i.value,
                  })}
                  >${i.key}</a
                >
              </li>
            `
          )}
        </ul>
      </div>
    `;
  },
})
export class GenericNavbarComponent extends LitElement {
  @Router() private router: Router;
  @property() items: { key: string; value: string }[] = [];
}
