import { html } from '@rxdi/lit-html';
import { translate } from '../i18n/translate';
import { InputValidityState, AbstractInput } from '@rxdi/forms';

export function InputErrorTemplate(input: AbstractInput) {
  if (input && input.invalid && (input.touched || input.dirty)) {
    const message = input.validationMessage;
    // const key = Object.keys(InputValidityState).filter(o => input.validity[o])[0];
    // message = translate(key, 'bg_BG');
    return html`
      <style>
        .validation-error {
          color: #a94442;
        }
      </style>
      <span style="padding-top: 5px;color: #a94442" class="validation-error">${message}</span>
    `;
  }
  return '';
}
